import { Injectable } from '@angular/core';
import { PaymentFacadeService } from '../../+state/services/payment-facade.service';
import { filter, map } from 'rxjs';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { LinkResult } from '../../types/link-result.type';

@Injectable({
  providedIn: 'root',
})
export class LinkStatusGuard {
  constructor(
    private paymentFacadeService: PaymentFacadeService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const linkId = route.paramMap.get('linkId');
    if (!linkId) return false;
    return this.paymentFacadeService.linkDetails$().pipe(
      filter(Boolean),
      map(({ status }) => {
        const resultParam = route.paramMap.get('result');
        const redirectFrom3ds = route.queryParamMap.get('redirectFrom3ds');
        if (redirectFrom3ds) {
          return true; // user has either completed or failed the 3ds challenge in an external url
        }
        if (status === 'active') {
          return [null, 'success', 'failed'].includes(resultParam)
            ? true // user is either beginning or has completed the payment session
            : this.router.createUrlTree([`${linkId}/info`]); // user is on an incorrect result page
        }
        const result = ({
          completed: 'already-paid',
          expired: 'finished',
          suspended: 'finished',
          frozen: 'frozen',
        }[status] || 'finished') as LinkResult;
        if (resultParam === result) {
          return true; // user is on the correct result page
        }
        return this.router.createUrlTree([`${linkId}/${result}`]); // redirect to correct result page
      }),
    );
  }
}
