import { PaymentState } from '../models/payment-state.model';
import { createReducer, on } from '@ngrx/store';
import { paymentActions } from '../actions/payment.actions';
import { paymentApiActions } from '../actions/payment-api.actions';

const initialState: PaymentState = {
  linkId: undefined,
  linkDetails: undefined,
  error: undefined,
  shopperInformation: undefined,
};

export const paymentReducer = createReducer<PaymentState>(
  initialState,
  on(paymentActions.openLink, (state, { linkId }) => ({
    ...state,
    linkId,
  })),
  on(paymentActions.setLinkFrozen, (state) => ({
    ...state,
    linkDetails: state.linkDetails
      ? {
          ...state.linkDetails,
          status: 'frozen',
        }
      : undefined,
  })),
  on(paymentApiActions.linkDetailsLoadedSuccess, (state, { linkDetails }) => ({
    ...state,
    linkDetails,
  })),
  on(paymentApiActions.linkDetailsLoadedError, (state, { error }) => ({
    ...state,
    error,
  })),
  on(paymentActions.submitShopperInformation, (state, { shopperInformation }) => ({
    ...state,
    shopperInformation,
  })),
);
