import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ShopperInformation } from '../../types/shopper-information.type';

export const paymentActions = createActionGroup({
  source: 'Payment',
  events: {
    'Open link': props<{ linkId: string }>(),
    'Set link frozen': emptyProps(),
    'Submit shopper information': props<{ linkId: string; shopperInformation: Partial<ShopperInformation> }>(),
  },
});
