/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './link-frontend.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as comKodypayGrpcCommon001 from '../../../../../com/kodypay/grpc/common/money.pb';
import * as comKodypayGrpcWombatV1002 from '../../../../../com/kodypay/grpc/wombat/v1/common.pb';
import * as comKodypayGrpcCommon003 from '../../../../../com/kodypay/grpc/common/pay-by-link.pb';
import * as googleProtobuf004 from '@ngx-grpc/well-known-types';
import * as options005 from '../../../../../options/team.pb';
import { GRPC_LINK_FRONTEND_SERVICE_CLIENT_SETTINGS } from './link-frontend.pbconf';
/**
 * Service client implementation for com.kodypay.grpc.wombat.v1.LinkFrontendService
 */
@Injectable({ providedIn: 'any' })
export class LinkFrontendServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /com.kodypay.grpc.wombat.v1.LinkFrontendService/GetLink
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetLinkResponse>>
     */
    getLink: (
      requestData: thisProto.GetLinkRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetLinkResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/com.kodypay.grpc.wombat.v1.LinkFrontendService/GetLink',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetLinkRequest,
        responseClass: thisProto.GetLinkResponse
      });
    },
    /**
     * Unary call: /com.kodypay.grpc.wombat.v1.LinkFrontendService/GetIntegratedPayment
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetIntegratedPaymentResponse>>
     */
    getIntegratedPayment: (
      requestData: thisProto.GetIntegratedPaymentRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetIntegratedPaymentResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/com.kodypay.grpc.wombat.v1.LinkFrontendService/GetIntegratedPayment',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetIntegratedPaymentRequest,
        responseClass: thisProto.GetIntegratedPaymentResponse
      });
    },
    /**
     * Unary call: /com.kodypay.grpc.wombat.v1.LinkFrontendService/GetIntegratedPaymentCardToken
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetIntegratedPaymentCardTokenResponse>>
     */
    getIntegratedPaymentCardToken: (
      requestData: thisProto.GetIntegratedPaymentCardTokenRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<thisProto.GetIntegratedPaymentCardTokenResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/com.kodypay.grpc.wombat.v1.LinkFrontendService/GetIntegratedPaymentCardToken',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetIntegratedPaymentCardTokenRequest,
        responseClass: thisProto.GetIntegratedPaymentCardTokenResponse
      });
    },
    /**
     * Unary call: /com.kodypay.grpc.wombat.v1.LinkFrontendService/GetPaymentMethods
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetPaymentMethodsResponse>>
     */
    getPaymentMethods: (
      requestData: thisProto.GetPaymentMethodsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetPaymentMethodsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/com.kodypay.grpc.wombat.v1.LinkFrontendService/GetPaymentMethods',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetPaymentMethodsRequest,
        responseClass: thisProto.GetPaymentMethodsResponse
      });
    },
    /**
     * Unary call: /com.kodypay.grpc.wombat.v1.LinkFrontendService/CreatePaymentSession
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CreatePaymentSessionResponse>>
     */
    createPaymentSession: (
      requestData: thisProto.CreatePaymentSessionRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CreatePaymentSessionResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/com.kodypay.grpc.wombat.v1.LinkFrontendService/CreatePaymentSession',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreatePaymentSessionRequest,
        responseClass: thisProto.CreatePaymentSessionResponse
      });
    },
    /**
     * Unary call: /com.kodypay.grpc.wombat.v1.LinkFrontendService/CompletePaymentSession
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CompletePaymentSessionResponse>>
     */
    completePaymentSession: (
      requestData: thisProto.CompletePaymentSessionRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CompletePaymentSessionResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/com.kodypay.grpc.wombat.v1.LinkFrontendService/CompletePaymentSession',
        requestData,
        requestMetadata,
        requestClass: thisProto.CompletePaymentSessionRequest,
        responseClass: thisProto.CompletePaymentSessionResponse
      });
    },
    /**
     * Unary call: /com.kodypay.grpc.wombat.v1.LinkFrontendService/CreateApplePay
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CreateApplePayResponse>>
     */
    createApplePay: (
      requestData: thisProto.CreateApplePayRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CreateApplePayResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/com.kodypay.grpc.wombat.v1.LinkFrontendService/CreateApplePay',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateApplePayRequest,
        responseClass: thisProto.CreateApplePayResponse
      });
    }
  };

  constructor(
    @Optional()
    @Inject(GRPC_LINK_FRONTEND_SERVICE_CLIENT_SETTINGS)
    settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'com.kodypay.grpc.wombat.v1.LinkFrontendService',
      settings
    );
  }

  /**
   * Unary call @/com.kodypay.grpc.wombat.v1.LinkFrontendService/GetLink
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetLinkResponse>
   */
  getLink(
    requestData: thisProto.GetLinkRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetLinkResponse> {
    return this.$raw
      .getLink(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/com.kodypay.grpc.wombat.v1.LinkFrontendService/GetIntegratedPayment
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetIntegratedPaymentResponse>
   */
  getIntegratedPayment(
    requestData: thisProto.GetIntegratedPaymentRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetIntegratedPaymentResponse> {
    return this.$raw
      .getIntegratedPayment(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/com.kodypay.grpc.wombat.v1.LinkFrontendService/GetIntegratedPaymentCardToken
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetIntegratedPaymentCardTokenResponse>
   */
  getIntegratedPaymentCardToken(
    requestData: thisProto.GetIntegratedPaymentCardTokenRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetIntegratedPaymentCardTokenResponse> {
    return this.$raw
      .getIntegratedPaymentCardToken(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/com.kodypay.grpc.wombat.v1.LinkFrontendService/GetPaymentMethods
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetPaymentMethodsResponse>
   */
  getPaymentMethods(
    requestData: thisProto.GetPaymentMethodsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetPaymentMethodsResponse> {
    return this.$raw
      .getPaymentMethods(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/com.kodypay.grpc.wombat.v1.LinkFrontendService/CreatePaymentSession
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CreatePaymentSessionResponse>
   */
  createPaymentSession(
    requestData: thisProto.CreatePaymentSessionRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CreatePaymentSessionResponse> {
    return this.$raw
      .createPaymentSession(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/com.kodypay.grpc.wombat.v1.LinkFrontendService/CompletePaymentSession
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CompletePaymentSessionResponse>
   */
  completePaymentSession(
    requestData: thisProto.CompletePaymentSessionRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CompletePaymentSessionResponse> {
    return this.$raw
      .completePaymentSession(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/com.kodypay.grpc.wombat.v1.LinkFrontendService/CreateApplePay
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CreateApplePayResponse>
   */
  createApplePay(
    requestData: thisProto.CreateApplePayRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CreateApplePayResponse> {
    return this.$raw
      .createApplePay(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
