import { Permission } from '@libs/shared/types';

const permissionKeys = [
  'accessCards',
  'accessUniverse',
  'accessCardAccounts',
  'accessPaymentTransactions',
  'accessPaymentTransactionFees',
  'accessSettlements',
  'accessPaymentLinks',
  'accessStatements',
  'accessDeposits',
  'increaseDepositAmount',
  'decreaseDepositAmount',
  'accessRefunds',
  'accessInsights',
  'accessBatchPayments',
  'addPayees',
  'deletePayees',
  'accessLoyalty',
  'accessMembership',
] as const;
type PermissionKey = (typeof permissionKeys)[number];

export const permissions: Record<PermissionKey, Permission> = {
  accessCards: {
    subject: 'KodyUniverse.KodyCard',
    level: 'Access',
  },
  accessCardAccounts: {
    subject: 'KodyUniverse.KodyCard.Accounts',
    level: 'Access',
  },
  accessUniverse: {
    subject: 'KodyUniverse',
    level: 'Access',
  },
  accessPaymentTransactions: {
    subject: 'KodyUniverse.PaymentTransactions',
    level: 'Access',
  },
  accessPaymentTransactionFees: {
    subject: 'KodyUniverse.PaymentTransactions.Fees',
    level: 'Access',
  },
  accessSettlements: {
    subject: 'KodyUniverse.Settlements',
    level: 'Access',
  },
  accessPaymentLinks: {
    subject: 'KodyUniverse.PayByLink',
    level: 'Access',
  },
  accessStatements: {
    subject: 'KodyUniverse.Invoices',
    level: 'Access',
  },
  accessDeposits: {
    subject: 'KodyUniverse.Deposits',
    level: 'Access',
  },
  increaseDepositAmount: {
    subject: 'KodyUniverse.Deposits.IncreaseAmount',
    level: 'Access',
  },
  decreaseDepositAmount: {
    subject: 'KodyUniverse.Deposits.DecreaseAmount',
    level: 'Access',
  },
  accessRefunds: {
    subject: 'KodyUniverse.PaymentTransactions.Refunds',
    level: 'Access',
  },
  accessBatchPayments: {
    subject: 'KodyUniverse.BatchPayments',
    level: 'Access',
  },
  accessInsights: {
    subject: 'KodyUniverse.Insights',
    level: 'Access',
  },
  addPayees: {
    subject: 'KodyUniverse.BusinessAccounts.Payees.AddNew',
    level: 'Access',
  },
  deletePayees: {
    subject: 'KodyUniverse.BusinessAccounts.Payees.Delete',
    level: 'Access',
  },
  accessLoyalty: {
    subject: 'KodyUniverse.Loyalty',
    level: 'Access',
  },
  accessMembership: {
    subject: 'KodyUniverse.Membership',
    level: 'Access',
  },
};
