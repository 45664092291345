import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormAlignment, PaymentTheme } from '../../interfaces/payment-theme.interface';
import { catchError, firstValueFrom, of } from 'rxjs';
import { BRAND_ASSET_ENDPOINT } from '../../tokens/brand-asset-endpoint.token';
import { WINDOW } from '@libs/shared/utilities/tokens/window.token';

@Injectable({
  providedIn: 'root',
})
export class PaymentBrandingService {
  private readonly defaultTheme: PaymentTheme = {
    formAlignment: 'default',
  };

  formAlignment: FormAlignment;
  logo: string;
  background: string;
  termsAndConditions: string | undefined;

  constructor(
    private http: HttpClient,
    @Inject(WINDOW) private window: Window,
    @Inject(BRAND_ASSET_ENDPOINT) private brandAssetEndpoint: string,
  ) {}

  async initializeBranding(): Promise<void> {
    const { pathname } = new URL(this.window.location.href);
    const [, linkId] = pathname.split('/');
    const [, brandId] = linkId.split('.');

    const theme = await this.getTheme(brandId);

    this.formAlignment = theme.formAlignment;
    this.logo = this.logoUrl(this.brandAssetEndpoint, brandId);
    this.background = this.backgroundUrl(this.brandAssetEndpoint, brandId);
    this.termsAndConditions = theme.termsAndConditionsUrl;

    this.window.document.documentElement.style.setProperty('--kp-background-image', `url(${this.background})`);

    // TODO - colour theme generation
  }

  private logoUrl = (url: string, brandId: string) => `${url}/${brandId}/logo.png`;
  private backgroundUrl = (url: string, brandId: string) => `${url}/${brandId}/background.png`;
  private themeUrl = (url: string, brandId: string) => `${url}/${brandId}/theme.json`;

  private async getTheme(brandId: string): Promise<PaymentTheme> {
    const url = this.themeUrl(this.brandAssetEndpoint, brandId);
    const theme = await firstValueFrom(this.http.get<PaymentTheme>(url).pipe(catchError(() => of(null))));

    return {
      ...this.defaultTheme,
      ...theme,
    };
  }
}
