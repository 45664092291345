import { Injectable } from '@angular/core';
import {
  CompletePaymentSessionRequest,
  CreateApplePayRequest,
  CreatePaymentSessionRequest,
  GetLinkRequest,
  GetLinkResponse,
  GetPaymentMethodsRequest,
  LinkFrontendServiceClient,
} from '@libs/core/grpc/com/kodypay/grpc/wombat/v1/link-frontend';
import { map, Observable, Subject, tap } from 'rxjs';
import { LinkDetails } from '../../types/link-details.type';
import { LinkPrompt as GrpcLinkPrompt, LinkStatus as GrpcLinkStatus } from '@libs/core/grpc/com/kodypay/grpc/wombat/v1/common';
import { LinkType as GrpcLinkType } from '@libs/core/grpc/generated/com/kodypay/grpc/common/pay-by-link.pb';
import { ShopperPrompt } from '../../types/shopper-prompt.type';
import { LinkStatus } from '../../types/link-status.type';
import { LinkType } from '../../types/link-type.type';
import { Address } from '../../types/address.type';
import type { PaymentMethodsResponse } from '@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types';
import type { ShopperInformation } from '../../types/shopper-information.type';
import type { AdyenPaymentResponse } from '@libs/feature-payments';
import { retryWithDelay } from '@libs/shared/utilities/grpc/retry-with-delay.operator';

@Injectable({
  providedIn: 'root',
})
export class LinkApiService {
  constructor(private client: LinkFrontendServiceClient) {}

  getPaymentMethods(linkId: string, until$: Subject<any>): Observable<PaymentMethodsResponse> {
    return this.client
      .getPaymentMethods(
        new GetPaymentMethodsRequest({
          linkId,
        }),
      )
      .pipe(
        retryWithDelay(20, 1000, until$),
        map(({ paymentMethods }) => JSON.parse(atob(paymentMethods))),
      );
  }

  makePayment(
    linkId: string,
    paymentMethod: unknown,
    browserInfo: unknown,
    origin: string,
    { firstName, lastName, email, phone, billingAddress, shippingAddress }: Partial<ShopperInformation>,
    paymentBrand: string | undefined,
  ): Observable<AdyenPaymentResponse> {
    return this.client
      .createPaymentSession(
        new CreatePaymentSessionRequest({
          linkId,
          paymentMethodData: btoa(JSON.stringify(paymentMethod)),
          shopperFirstName: firstName,
          shopperLastName: lastName,
          shopperEmail: email,
          shopperPhone: phone,
          billingAddress: billingAddress ? this.addressToGrpc(billingAddress) : undefined,
          shippingAddress: shippingAddress ? this.addressToGrpc(shippingAddress) : undefined,
          paymentMethodBrand: paymentBrand,
          browserInfo: browserInfo ? btoa(JSON.stringify(browserInfo)) : undefined,
          origin,
        }),
      )
      .pipe(map(({ paymentResponse }) => JSON.parse(atob(paymentResponse))));
  }

  submitAdditionalDetails(linkId: string, details: unknown): Observable<AdyenPaymentResponse> {
    return this.client
      .completePaymentSession(
        new CompletePaymentSessionRequest({
          linkId,
          details: btoa(JSON.stringify(details)),
        }),
      )
      .pipe(map(({ paymentResponse }) => JSON.parse(atob(paymentResponse))));
  }

  createApplePaySession(linkId: string): Observable<unknown> {
    return this.client
      .createApplePay(new CreateApplePayRequest({ linkId }))
      .pipe(map(({ responseData }) => JSON.parse(atob(responseData))));
  }

  getLinkDetails(linkId: string): Observable<LinkDetails> {
    return this.client.getLink(new GetLinkRequest({ linkId })).pipe(map(this.linkDetailsFromGrpc));
  }

  private linkDetailsFromGrpc = ({
    description,
    prompts,
    status,
    type,
    amount,
    storeName,
    defaultLanguage,
    supportedLanguages,
    countryCode,
    gatewayMerchantId,
  }: GetLinkResponse.AsObject): LinkDetails => ({
    description,
    isDeposit: this.linkTypeFromGrpc(type)?.includes('deposit') || false,
    prompts: prompts.map(this.shopperPromptFromGrpc),
    status: this.linkStatusFromGrpc(status),
    type: this.linkTypeFromGrpc(type),
    amount: amount?.value ? parseFloat(amount.value) : 0,
    currencyCode: amount?.currencyCode || 'GBP',
    defaultLanguage,
    storeName,
    supportedLanguages,
    countryCode,
    gatewayMerchantId,
  });

  private shopperPromptFromGrpc = (prompt: GrpcLinkPrompt): ShopperPrompt =>
    (
      ({
        [GrpcLinkPrompt.NAME]: 'name',
        [GrpcLinkPrompt.EMAIL]: 'email',
        [GrpcLinkPrompt.PHONE]: 'phone',
        [GrpcLinkPrompt.BILLING_ADDRESS]: 'billingAddress',
        [GrpcLinkPrompt.DELIVERY_ADDRESS]: 'shippingAddress',
      }) as const
    )[prompt];

  private linkStatusFromGrpc = (status: GrpcLinkStatus): LinkStatus =>
    (
      ({
        [GrpcLinkStatus.ACTIVE]: 'active',
        [GrpcLinkStatus.EXPIRED]: 'expired',
        [GrpcLinkStatus.COMPLETED]: 'completed',
        [GrpcLinkStatus.SUSPENDED]: 'suspended',
        [GrpcLinkStatus.FREEZE]: 'frozen',
      }) as const
    )[status];

  // TODO: Check this has not broken anything
  private linkTypeFromGrpc = (type: Partial<GrpcLinkType>): LinkType | undefined =>
    (
      ({
        [GrpcLinkType.MULTIPLE]: 'multiple',
        [GrpcLinkType.SINGLE]: 'single',
        [GrpcLinkType.MULTIPLE_DEPOSIT]: 'multiple deposit',
        [GrpcLinkType.SINGLE_DEPOSIT]: 'single deposit',
      }) as const
    )[type];

  private addressToGrpc = ({ country, address }: Address): CreatePaymentSessionRequest.Address =>
    new CreatePaymentSessionRequest.Address({
      houseNumberOrName: address,
      country,
      city: '.',
      street: '.',
      postcode: '.',
    });
}
