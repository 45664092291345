/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
export enum LinkPrompt {
  EMAIL = 0,
  NAME = 1,
  PHONE = 2,
  BILLING_ADDRESS = 3,
  DELIVERY_ADDRESS = 4
}
export enum LinkStatus {
  ACTIVE = 0,
  EXPIRED = 1,
  COMPLETED = 2,
  SUSPENDED = 3,
  FREEZE = 4
}
