import { Route } from '@angular/router';
import { LinkStoreNameResolver } from '@libs/app-kodypayments/static/resolvers/link-store-name/link-store-name.resolver';
import { LinkStatusGuard } from '@libs/app-kodypayments/static/guards/link-status/link-status.guard';
import { LinkDetailsGuard } from '@libs/app-kodypayments/static/guards/link-details/link-details.guard';
import { LinkDescriptionResolver } from '@libs/app-kodypayments/static/resolvers/link-description/link-description.resolver';
import { LinkShopperInformationRequiredGuard } from '@libs/app-kodypayments/static/guards/link-shopper-information-required/link-shopper-information-required.guard';

export const appRoutes: Route[] = [
  {
    path: ':linkId',
    loadComponent: () =>
      import('@libs/app-kodypayments/lazy/pages/link-host-page/link-host-page.component').then((m) => m.LinkHostPageComponent),
    canActivate: [LinkDetailsGuard],
    title: LinkStoreNameResolver,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'info',
      },
      {
        path: 'info',
        canActivate: [LinkStatusGuard],
        loadComponent: () =>
          import('@libs/app-kodypayments/lazy/pages/link-info-page/link-info-page.component').then((m) => m.LinkInfoPageComponent),
        title: LinkDescriptionResolver,
      },
      {
        path: 'pay',
        loadComponent: () =>
          import('@libs/app-kodypayments/lazy/pages/link-payment-page/link-payment-page.component').then((m) => m.LinkPaymentPageComponent),
        canActivate: [LinkStatusGuard, LinkShopperInformationRequiredGuard],
        title: LinkDescriptionResolver,
      },
      {
        path: 'handle-redirect',
        loadComponent: () =>
          import('@libs/app-kodypayments/lazy/pages/link-handle-redirect-page/link-handle-redirect-page.component').then(
            (m) => m.LinkHandleRedirectPageComponent,
          ),
        canActivate: [LinkStatusGuard],
        title: LinkDescriptionResolver,
      },
      {
        path: ':result',
        loadComponent: () =>
          import('@libs/app-kodypayments/lazy/pages/link-result-page/link-result-page.component').then((m) => m.LinkResultPageComponent),
        canActivate: [LinkStatusGuard],
        title: LinkDescriptionResolver,
      },
    ],
  },
];
